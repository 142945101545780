import { Outlet, Navigate, useSearchParams, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { isAuthenticatedState, tokenState } from '../../Recoil'
import { ResultStatus, ROUTES } from '../../constants'
import localStorageKeys from '../../constants/localStorageKeys'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { loginSuperUser } from '../../api/apis'

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(isAuthenticatedState)
  const [token, setToken] = useRecoilState(tokenState)
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get(localStorageKeys.InspectionId)
  const isInitialAuth = useRef(false)
  const location = useLocation()
  const pathname = location.pathname
  const search = location.search

  const isRootOrLoginWithoutSearch = useMemo(() => {
    return pathname === '/' || (pathname.includes(ROUTES.PUBLIC_PATH.LOGIN) && !search)
  }, [pathname, search])

  const authenticate = useCallback(async() => {
    if(isRootOrLoginWithoutSearch) {
      return
    }

    if (token || isInitialAuth.current) {
      setIsAuthenticated(true)
    } else {
      isInitialAuth.current = true
      const response = await loginSuperUser()
      if (response?.status === ResultStatus.OK) {
        setIsAuthenticated(true)
        setToken(response.data.token)
      } else {
        setIsAuthenticated(false)
      }
    }
  }, [uuid, token, pathname])

  useEffect(() => {
    if (isAuthenticated === undefined) {
      authenticate()
    }
    return () => { isInitialAuth.current = false }
  }, [isAuthenticated])

  if (isAuthenticated) {
    return <Outlet />
  }

  if (isAuthenticated === false) {
    return <Navigate to={ROUTES.PUBLIC_PATH.THANK_YOU} replace />
  }

  if(isRootOrLoginWithoutSearch) {
    return <Navigate to={ROUTES.PUBLIC_PATH.LOGIN} replace />
  }

  return null
}

export default ProtectedRoute